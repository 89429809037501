
import { Component, Vue } from "vue-property-decorator";
import TopAppBar from "@/components/base/TopAppBar.vue";

@Component({
  components: {
    TopAppBar,
  },
  metaInfo: {
    title: "Пользовательское соглашение",
  },
})
export default class TermsOfService extends Vue {}
